@import "../variables.scss";

.content{
    font-size: 12px;
    text-align: center;
}

.row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $gap;
  margin-bottom: $gap;
  text-transform: uppercase;
}

.modalTitle{
  align-items: center;
  text-align: center;
  font-size:var(--exp-text-title);
}

.closeButton{
  position: absolute;
  right: 0px;
  top:0px;
}

.contentTitle{
  font-style: normal;
  font-weight: 800;
  font-size: 17.21px;
  line-height: 21px;
  color:#3CFFA1;
  margin: 0px;
}

.share-button{
  position: fixed;
  left: 10%;
  width: 80%;
  bottom: 20px;
}
