@import "../variables.scss";

.mainContainer {
    width:($size * 5) + ($gap * 5);
    margin:10px auto;
  }

  
.row {
  display: flex;
  gap: $gap;
  margin: auto;
  margin-bottom: $gap;
  text-transform: uppercase;
  width: 270px;
}

.buttonCol{
  text-align: center;
}