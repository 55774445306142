@mixin keybase {
    
    cursor: pointer;
    border: none;
    border-radius: 3px;
    color: white;  
    display: flex;
    align-items: center;
    justify-content: center;
    margin:0 3px 0 0; 
    text-align:center;
    width: 32px;
    height: 45px;
    font-style: normal;
    font-weight: 700;
    font-size: 15.3659px;
  }


.key {
    @include keybase;
    
    
  }

  .correct {
    @include keybase;
    background-color:  var(--explodle-color-correct);;
    border:  var(--explodle-color-correct) solid 2px;
    color: black;
  }
  
  .present {
    @include keybase;
    background-color: #FBFF4F;
    border: #FBFF4F solid 2px;
    color: black;
  }
  
  .absent {
    @include keybase;
    background-color: #d40917;
  }

  .clear{
    @include keybase;
    background: #505E87;
    border: 0.960366px solid #6273A0;
    border-radius: 4.80183px;
  }