@mixin keybase {
    background-color: #333;
    cursor: pointer;
    border: none;
    border-radius: 3px;
    color: white;  
    display: flex;
    align-items: center;
    justify-content: center;
    margin:0 3px 0 0; 
    background: #505E87;
    border: 0.960366px solid #6273A0;
    border-radius: 4.80183px;
    font-style: normal;
    font-weight: 700;
    font-size: 15.3659px;
  }
  
  .keyboardContainer {
    flex-wrap: wrap;
    max-width:364px;
    margin: 0 auto;
    gap: 5px;
    padding:5px;    
  }

  .keyRow{
    display:-webkit-flex;
    margin:0 auto 7px;
    line-height:45px;
  }

  .key {
    @include keybase;    
    text-align:center;
    width: 38px;
    height: 45px;
  }
  .emptyKey {
    width: 19px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .enterKey {
    @include keybase;
    width: 80px;
    height: 45px;
    font-size: 12px;
    background-color: #8432EC;
  }
  
  .deleteKey {
    @include keybase;
    width: 45px;
    height: 45px;
    font-size: 32px;
    background: url('../../assets/backspace.png')  #505e87;
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-position-y: 50%;
  }

  @media screen and (max-width: 768px) {
    .deleteKey {
      max-width:364px;
    }
  }
    
  
