.dot{
    position: relative;
    left: 50px;
    top:-70px;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    background: rgb(255, 95, 95,0);
    border-radius: 50%;
    box-shadow: 0 0 20px rgb(237, 249, 224, 0), 
                0 0 40px rgb(255, 106, 95, 0),
                0 0 60px rgb(255, 114, 95, 0),
                0 0 80px rgb(255, 250, 95,0);
    transition: 0.4s;

}

.explode{
    position: relative;
    left: 50px;
    top:-70px;
    border-radius: 100%;
    background: rgb(255, 95, 95,0.7);
    box-shadow: 0 0 50px 30px rgb(254, 255, 254,.5), 
                0 0 50px 40px rgb(202, 32, 32,.5),
                0 0 50px 60px rgb(202, 32, 32,.5),
                0 0 50px 120px rgb(255, 191, 95,.5), 
                0 0 50px 240px rgb(255, 95, 95,.5), 
                0 0 50px 480px rgb(255, 95, 95,.5), 
                0 0 50px 960px rgb(116, 44, 44,.5), 
}

.boom{
    position: relative;
    margin-left: -80px;
    margin-top: -80px;
    transition: 0.4s;
    width: 0px;
    height: 0px;
}

.boomExplode{
    position: relative;
    margin-left: -172px;
    margin-top: -80px;
    width: 404px;
    height: 255px;
    line-height: 30px;
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    text-align: center;
    background-image: url("../../../assets/boom.fw.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 998;
}


.letters{
    transition: 0.3s;
    position: absolute;
    margin-left: -13px;
    margin-top: -143px;
    width: 0px;
    height: 0px;
}

.lettersExplode{
    transition: 0.3s;
    position: absolute;
    margin-left: -11px;
    margin-top: -183px;
    width: 145px;
    height: 108px;
    line-height: 30px;
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    text-align: center;
    background-image: url("../../../assets/letters.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 999;
    opacity: 0.8;
}