
.statisticsNumber{
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 0px;
    margin: 0px 0px 18px 0px;
  }
  
  .statisticsRow{
    margin-top: 20px;
    margin-bottom: 11px;
  }
  
  .statisticsLabel{
    font-style: normal;
    font-weight: 500;
    font-size: var(--exp-text-p);
    color:#B1CDF0
  }
  
  hr{
    margin: 10px;
    background-color: rgba(63, 82, 116, 0.7);
  }
  
  .column{
    width: 25%;
  }
  
  .pieContainer {
    width:100%;
    max-width: 200px;
    margin:auto;
    text-align: center;
  }

  .subtitle{
    font-size:var(--exp-text-subtitle);
  }

