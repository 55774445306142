@import "../variables.scss";

@mixin box {
    font-weight: 700;
    font-size: 22px;
    width: $size;
    height: $size;
    border: solid 2px #55648F;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

.correct {
  @include box;
  background-color:  var(--explodle-color-correct);
  border:  var(--explodle-color-correct) solid 2px;
  color: black;
}
  
.present {
  @include box;
  background-color: #e5f321;
  border: #e5f321 solid 2px;
  color: black;
}

.absent {
  @include box;
  background: #d40917;
  border: 2px solid #374457;
  color: white;
}


.edit {
  @include box;
  color: black;
  background-color: #FFFFFF;
}

.empty {
  @include box;
  background-color: rgba(85, 100, 143, 0.9);
}