@import "../variables.scss";

@mixin box {
    font-weight: 700;
    font-size: 22px;
    width: $size;
    height: $size;
    border: solid 2px #55648F;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    border-radius: 8px;
}

.correct {
  @include box;
  background-color: var(--explodle-color-correct);
  border: var(--explodle-color-correct) solid 2px;
  color: black;
}
  
.present {
  @include box;
  background-color: #FBFF4F;
  border: #FBFF4F solid 2px;
  color: black;
}

.absent {
  @include box;
  background: #d40917;
  border: 2px solid #374457;
  color: white;
}

.edit {
  @include box;
  color: black;
  background-color: #FFFFFF;
}

.empty {
  @include box;
  background-color: rgba(85, 100, 143, 0.9);
}

  .flipCard {
    display: flex;
    width: $size;
    height: $size;
    background-color: transparent;
  }  

  .flipCardBack {
    @include box;
    color: black;
    background-color: #FFFFFF;
    transform: rotateY(180deg);
  }  

  .flipCardInner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  .flipCardInnerRotated {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    transform: rotateY(180deg);
  }


 