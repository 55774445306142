@import "../variables.scss";

.content{
    font-size: var(--exp-text-p);
    text-align: center;
}
/*
@media screen and (min-width: 768px) {
  .content{
    font-size: 15px;
  }
}*/

.row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $gap;
  margin-bottom: $gap;
  text-transform: uppercase;
}

.modalTitle{
  align-items: center;
  text-align: center;
  font-size:var(--exp-text-title);
}

.closeButton{
  position: absolute;
  right: 0px;
  top:0px;
}

.redText{
  color: rgb(210, 61, 61);
  font-weight: 800;
}


