@import "./variables.scss";

.mainContainer {
  width:($size * 5) + ($gap * 5);
  margin:10px auto;
}


.email{
  font-style: normal;
  font-weight: 800;
  font-size: 17.21px;
  line-height: 21px;
  color:#3CFFA1;
  margin: 0px;
}
