.container  {
    width:365px;
    margin:auto;
    text-align: center;
  }

.fuse  {
    height: 4px;
    width: 260px;
    display:inline-block;
    position: relative; 
    background-color: #e0e0de;
    border-radius: 10px;
}

.lineContainer{
    width: 100%;
    height: 4px;
    background-color: white;
}

.lineProgress{
    height: 4px;
    background-color: black;
    width: 260px;
    display:inline-block;
    position: relative; 
    border-radius: 10px;
}



.dynamite{
    display:inline-block;
    margin-left: -10px;
    width: 98px;
    height: 30px;
    line-height: 30px;
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    text-align: center;
    background-image: url("../../assets/dynamite.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.sparkContainer{
    width: 100%;
    position: absolute;
    margin-left:-20px;
    margin-top:-15px;
    text-align: left;
}

.explotion{
    position: absolute;
    margin: auto;
}